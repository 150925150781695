<template>
  <modal name="OptInToFlyGreen" class="mainFlyGreenPopup" transition="pop-out" :adaptive="true">
    <div class="OptInToFlyGreen-page-popup">
      <div class="OptInToFlyGreen-page-popup__container">
        <div class="OptInToFlyGreen-page-popup__title">
          <span class="OptInToFlyGreen-page-popup__title OptInToFlyGreen-page-popup__title--color">Opt in to Fly</span>green
        </div>
        <p class="OptInToFlyGreen-page-popup__p">
<pre v-if="dMobile">
FLYJETS is committed to our FLYGreen Energy
Initiative, and we want to work with you as a
member of <span>TEAM GREEN</span>.  With each flight booking,
we will offer additional FLYRewards - over and
above the amount ordinarily granted with each flight
- to those users who elect to offset expected
emissions from their trip with carbon offsets,
and/or fly with some amount of Sustainable
Aviation Fuel (SAF), pending availability.
</pre>
<pre v-else>
FLYJETS is committed to our FLYGreen Energy Initiative, and we
want to work with you as a member of <span>TEAM GREEN</span>.  With each
flight booking, we will offer additional FLYRewards - over and
above the amount ordinarily granted with each flight - to those
users who elect to offset expected emissions from their trip with
carbon offsets, and/or fly with some amount of Sustainable
Aviation Fuel (SAF), pending availability.
</pre>
        </p>
        <div class="OptInToFlyGreen-page-popup__hr"></div>

        <div class="OptInToFlyGreen-page-popup__checkboxes">
          <label class="">
            <input class="form__checkbox" type="checkbox" v-model="opt" value="Opt"/>
            <i class="checkbox-icon"></i>
            <span class="checkbox-box">
              Opt in to FLYGreen with each
              <br v-if="dMobile">
              flight booking
              </span>
          </label>
          <label class="">
            <input class="form__checkbox" type="checkbox" v-model="opt" value="decide"/>
            <i class="checkbox-icon"></i>
            <span class="checkbox-box">
              I'll decide about offsetting my trip each
              <br v-if="dMobile">
              time I book a flight
            </span>
          </label>
        </div>

        <button
          class="buttonGreen OptInToFlyGreen-page-popup__button"
          :class="{'buttonGreen-disabled': buttonDisabled()}"
          :disabled="buttonDisabled()"
          @click="closePopup(); sendOpt()"
        >
          <span class="text">Complete</span>
        </button>
      </div>
      <div class="modal__close OptInToFlyGreen-page-popup__close" @click="closePopup">
        <SvgIcon name="close-white"/>
      </div>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import './OptInToFlyGreen.styl'

  export default {
    name: 'OptInToFlyGreen',
    data: () => ({
      opt: [],
      dMobile: undefined,
    }),
    methods: {
      sendOpt () {
        console.log('Opt', this.opt)
      },
      buttonDisabled () {
        return !this.opt.length > 0
      },
      closePopup () {
        this.$modal.hide('OptInToFlyGreen')
      },
    },
    mounted () {
      this.dMobile = document.querySelector('html').classList.contains('d-mobile')
    },
    components: {
      SvgIcon,
    },
  };
</script>
